/**
 * Converts a hex color code to an RGB string.
 *
 * @param hex - The hex color code (e.g., "#ff5733", "ff5733", "#abc", or "abc").
 * @returns The RGB string in the format `rgb(r, g, b)` if valid, otherwise `null`.
 */
export const hexToRgb = (hex: string): string | null => {
  // Utility function to validate hex code (3 or 6 hexadecimal characters)
  const isValidHex = (input: string): boolean => {
    const hexRegex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexRegex.test(input);
  };

  if (typeof hex !== "string") {
    // If input is not a string, return null
    return null;
  }

  // Remove '#' if present
  const sanitizedHex = hex.startsWith("#") ? hex.slice(1) : hex;

  // Validate the hex code
  if (!isValidHex(sanitizedHex)) {
    return null;
  }

  let cleanedHex = sanitizedHex;

  // If 3 characters, expand to 6 by duplicating each character
  if (cleanedHex.length === 3) {
    cleanedHex = cleanedHex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse the hex string to an integer
  const bigint = parseInt(cleanedHex, 16);

  // Extract the red, green, and blue components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgb(${r}, ${g}, ${b})`;
};
