import Image from "next/image";
import { Content } from "./Content";
import { ImageToUrl } from "services/imageToUrl";
import styles from "./HeroBannerV2.module.scss";
import { BulletProps, ContainerProps } from "./types";

export const Container = ({ data, page }: ContainerProps) => {
  const { imageMobile, imageDesktop, textContainerAlignment } = data;

  // Data instance extract from radio component
  const choiceTemplate = (instance: BulletProps) => {
    return instance.selectionValues[0].key;
  };

  // Images
  const coverImageMobile = imageMobile ? ImageToUrl(imageMobile) : null;
  const coverImageDesktop = ImageToUrl(imageDesktop);

  // Background
  const backgroundAlignmaent =
    choiceTemplate(textContainerAlignment) === "false" ? "left" : "right";
  const backgoundStyles = {
    backgroundImage: `url(${coverImageDesktop})`,
    backgroundPosition: backgroundAlignmaent,
  };

  return (
    <article className={styles.wrapper}>
      <div className={styles.container}>
        {coverImageMobile ? (
          <div className={styles.image}>
            <Image src={coverImageMobile} alt="" fill />
          </div>
        ) : null}
        {coverImageDesktop ? (
          <div className={styles.backgroundWrapper}>
            <div
              className={styles.backgroundContainer}
              style={backgoundStyles}
            ></div>
          </div>
        ) : null}
        <Content data={data} page={page} />
      </div>
    </article>
  );
};
