import { BrRichTextContent } from "../BrRichTextContent";
import { useResize } from "hooks/useResize";
import { getClassNames } from "services/getClassNames";
import { hexToRgb } from "services/hexToRgbConverter";
import { linkAttributes } from "services/linkAttributes";
import clsx from "clsx";
import styles from "./HeroBannerV2.module.scss";
import { HeroBannerTagManager } from "./HeroBannerTagManager";
import { BulletProps, ContentProps } from "./types";
import { useContext } from "react";
import { PageCheck } from "services/pageCheck";
import { PathContext } from "state_management/Contexts";

export const Content = ({ data, page }: ContentProps) => {
  const {
    heading,
    subheading,
    description,
    ctaGroup,
    textAlignment,
    hexHeadingMobile,
    hexSubheadingMobile,
    hexDescriptionMobile,
    hexHeadingDesktop,
    hexSubheadingDesktop,
    hexDescriptionDesktop,
    hexMobile,
    hexDesktop,
    fontSize,
    textContainerAlignment,
    textContainerSize,
  } = data;

  const { ctaButtons, buttonsSize } = ctaGroup;

  const windowSize = useResize();
  const path = useContext(PathContext);
  const tagPage = PageCheck(path);

  const choiceTemplate = (instance: BulletProps) => {
    return instance.selectionValues[0].key;
  };

  const ctaSize = choiceTemplate(buttonsSize) === "false" ? "Large" : "Medium";
  const textSize = choiceTemplate(fontSize);
  const mobileTextAlignment =
    choiceTemplate(textAlignment) === "false" ? "centered" : "left";
  const containerAlignmaent =
    choiceTemplate(textContainerAlignment) === "false" ? "Right" : "Left";
  const containerSize =
    choiceTemplate(textContainerSize) === "false" ? "Large" : "Small";

  // Dynamic colors
  const colors = {
    heading:
      windowSize < 821
        ? hexToRgb(hexHeadingMobile) ?? `rgb(0,0,0)`
        : hexToRgb(hexHeadingDesktop) ?? `rgb(0,0,0)`,
    subheading:
      windowSize < 821
        ? hexToRgb(hexSubheadingMobile) ?? `rgb(0,0,0)`
        : hexToRgb(hexSubheadingDesktop) ?? `rgb(0,0,0)`,
    description:
      windowSize < 821
        ? hexToRgb(hexDescriptionMobile) ?? `rgb(0,0,0)`
        : hexToRgb(hexDescriptionDesktop) ?? `rgb(0,0,0)`,
    containerBackground:
      windowSize < 821
        ? hexToRgb(hexMobile) ?? `rgb(255,255,255)`
        : hexToRgb(hexDesktop) ?? `transparent`,
  };

  // Dynamic Styles
  const headingStyles = { color: colors.heading };
  const subheadingStyles = { color: colors.subheading };
  const descriptionStyles = { color: colors.description };
  const containerStyles = { backgroundColor: colors.containerBackground };

  // Buttons render rule
  const buttonsToRender =
    textSize === "XXXL" || textSize === "XXXXL"
      ? ctaButtons.slice(0, 1)
      : ctaButtons;

  return (
    <section
      className={clsx(
        getClassNames(
          [
            "contentWrapper",
            `contentWrapper${containerAlignmaent}`,
            `contentWrapper${containerSize}`,
          ],
          styles
        )
      )}
      style={containerStyles}
      data-testid="content-wrapper"
    >
      <div
        className={clsx(styles.contentContainer, {
          [styles.contentContainerCentered]: mobileTextAlignment === "centered",
        })}
        data-testid="content-container"
      >
        {heading ? (
          <h1
            style={headingStyles}
            className={clsx(
              getClassNames(["heading", `heading${textSize}`], styles)
            )}
          >
            {heading}
          </h1>
        ) : null}
        {subheading ? (
          <p
            className={clsx(
              getClassNames(["subheading", `subheading${textSize}`], styles)
            )}
            style={subheadingStyles}
          >
            {subheading}
          </p>
        ) : null}
        {description ? (
          <div
            className={clsx(
              getClassNames(
                [
                  "description",
                  "descriptionCentered",
                  `description${textSize}`,
                ],
                styles
              )
            )}
            style={descriptionStyles}
          >
            <BrRichTextContent
              data-testid="BrRichTextContent"
              page={page}
              content={{
                html: description.value,
              }}
            />
          </div>
        ) : null}
      </div>
      {buttonsToRender.length ? (
        <div
          className={clsx(styles.cta, {
            [styles.ctaCentered]: mobileTextAlignment === "centered",
          })}
        >
          {buttonsToRender.map((button, index) => {
            const { ctaLabel, ctaLink, newTab, ctaType } = button;
            const ctaProps = linkAttributes(ctaLink, newTab);
            const ctaTypeChoise =
              choiceTemplate(ctaType) === "false" ? "Secondary" : "Primary";

            return (
              <a
                className={clsx(
                  getClassNames(
                    [
                      "ctaButton",
                      `ctaButton${ctaTypeChoise}`,
                      `ctaButton${ctaSize}`,
                    ],
                    styles
                  )
                )}
                {...ctaProps}
                key={index}
                onClick={() => HeroBannerTagManager(ctaLabel, tagPage)}
              >
                {ctaLabel}
              </a>
            );
          })}
        </div>
      ) : null}
    </section>
  );
};
